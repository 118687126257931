import { actionTypes as ActionTypes } from './actionTypes';

export { ActionTypes }

export const saveProfile = (profile) => ({
    type: ActionTypes.SAVE_PROFILE,
    domain : ActionTypes.DOMAIN,
    profile : profile
});

export const setLanguage = (language) => ({
    type: ActionTypes.SET_LANGUAGE,
    domain : ActionTypes.DOMAIN,
    language : language
});