import './reader.scss';
import React from 'react';

import { getConsultants } from "../../../services/consultants";

import {
    ListItem,
    ListItemIcon,
    ListItemText
  } from '@material-ui/core';
  
  import {
    PermPhoneMsgTwoTone as Call,
    VideocamTwoTone as VideoCall,
    ChatTwoTone as Messages
  } from '@material-ui/icons';
  
  import { Link, useHistory } from 'react-router-dom';

import { ReaderList } from '../../parts/reader/list';

import { v5 as uuidv5 } from 'uuid';
import { useSelector, useDispatch } from "react-redux";

import { sendInvite } from '../../../actions/chatActions';
import { startCall } from '../../../actions/rtcActions';

function chatConsultant(dispatch, history, userId, displayName, consultantId, consultantDisplayName, useVideo) {
  //if the userId is not known: he/she needs to login  
  if (userId) {
    let conversationId = uuidv5(userId, consultantId);

    //dispatch the invite
    dispatch(sendInvite(conversationId, userId, displayName, consultantId, consultantDisplayName));
    
    history.push("/conversation/" + conversationId);
  } else {
    //redirect to signup
    history.push("/signup");
  }
}

function ChatNowLink(props) {
    const { icon, primary, userId, displayName, consultantId, consultantDisplayName } = props;
    const dispatch = useDispatch();
    const history = useHistory();
  
    const renderLink = React.useMemo(
      () => React.forwardRef((itemProps, ref) => <a ref={ref} {...itemProps} />),
      [],
    );
  
    return (
      <ListItem button component={renderLink} className="listItem" onClick={() => chatConsultant(dispatch, history, userId, displayName, consultantId, consultantDisplayName)}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />      
      </ListItem>      
    );
  }

function callConsultant(dispatch, history, userId, displayName, consultantId, consultantDisplayName, useVideo) {
  //if the userId is not known: he/she needs to login  
  if (userId) {
    let conversationId = uuidv5(userId, consultantId);

    //dispatch the invite
    dispatch(sendInvite(conversationId, userId, displayName, consultantId, consultantDisplayName));
    
    //then dispatch starting the call
    dispatch(startCall(conversationId, useVideo));
  } else {
    //redirect to signup
    history.push("/signup");
  }
}


function CallDirectLink(props) {
  const { icon, primary, userId, displayName, consultantId, consultantDisplayName, useVideo } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  //const renderLink = ({itemProps}) => <a onClick={() => callConsultant(dispatch, userId, consultantId, useVideo)} {...itemProps} />;
  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <a ref={ref} {...itemProps} />),
    [],
  );

  return (
    <ListItem button component={renderLink} className="listItem" onClick={() => callConsultant(dispatch, history, userId, displayName, consultantId, consultantDisplayName, useVideo)}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />      
    </ListItem>      
  );
}



export const ReadersScreen = () => {
    const [consultants, setConsultants] = React.useState([]);
    const {userId, displayName} = useSelector(state => ({ userId : state.auth.userId, displayName : state.profile.displayName }));
    
    React.useEffect(() => {
        getConsultants().then(x => {
            setConsultants(x.data);
        });
    }, []);

    return <div className="readersscreen clearfix"> 
        {consultants.map(c => <ReaderList
                key={c.userId}
                profileStatus={c.profileStatus || "NA"}
                profileImage={c.profileImage}
                rating={c.rating || 2.5}>
            <Link className="readerInformation" to={`/reader/${c.userId}`}>
                <h2>{c.displayName} <span className="price">€ 1,50 /min</span></h2>
                <ul>
                    {c.languages?.map(l => <li key={l}>{l}</li>)}                    
                </ul>                       
            </Link>
            <div className="contactreader">                
                <p>{c.aboutShort}</p>
                <div className="directcontact">
                    <div className="buttons">
                        <CallDirectLink useVideo={false} userId={userId} consultantId={c.userId} displayName={displayName} consultantDisplayName={c.displayName} primary="Call Now" icon={<Call />} />
                        <CallDirectLink useVideo={true} userId={userId}  consultantId={c.userId} displayName={displayName} consultantDisplayName={c.displayName} primary="Video Call" icon={<VideoCall />} />
                        <ChatNowLink userId={userId}  consultantId={c.userId} displayName={displayName} consultantDisplayName={c.displayName} primary="Chat Now" icon={<Messages />} />
                    </div>
                </div>            
            </div>
        </ReaderList>)}
    </div>
}    