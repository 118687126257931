export const actionTypes = {
    DOMAIN : 'CHAT',

    HISTORY_RECEIVED : 'HISTORY_RECEIVED',
    HISTORIC_EVENTS_RECEIVED : 'HISTORIC_EVENTS_RECEIVED',
    UPDATE_CHATS_VIEW : 'UPDATE_CHATS_VIEW',
    SET_CONSULTS_OVERVIEW : 'SET_CONSULTS_OVERVIEW',
    EXPAND_CONSULTANT : 'EXPAND_CONSULTANT',
    SELECT_CONVERSATION : 'SELECT_CONVERSATION',
    ADD_SPREAD : 'ADD_SPREAD',
    SAVE_DRAFT : 'SAVE_DRAFT',
    SEND_DRAFT : 'SEND_DRAFT',
    SEND_INVITE : 'SEND_INVITE',
    START_CONVERSATION : 'START_CONVERSATION',   
    
    MESSAGE_RECEIVED : 'MESSAGE_RECEIVED',
    
    TEXT_MESSAGE_RECEIVED : 'TEXT_MESSAGE_RECEIVED',
    SPREAD_MESSAGE_RECEIVED : 'SPREAD_MESSAGE_RECEIVED',
    SPREADREQUEST_MESSAGE_RECEIVED : 'SPREADREQUEST_MESSAGE_RECEIVED',
    INVITE_RECEIVED : 'INVITE_RECEIVED',
    AUDIO_INTERMEDIATE_MESSAGE_RECEIVED : 'AUDIO_INTERMEDIATE_MESSAGE_RECEIVED',
    AUDIO_MESSAGE_RECEIVED : 'AUDIO_MESSAGE_RECEIVED',    
    ALBUM_INTERMEDIATE_MESSAGE_RECEIVED : 'ALBUM_INTERMEDIATE_MESSAGE_RECEIVED',
    ALBUM_MESSAGE_RECEIVED : 'ALBUM_MESSAGE_RECEIVED',


    WEB_RTC : 'WEB_RTC',
    RTC_SDP_RECEIVED : 'RTC_SDP_RECEIVED',
    RTC_CANDIDATE_RECEIVED : 'RTC_CANDIDATE_RECEIVED',
    RTC_HANGUP_RECEIVED : 'RTC_HANGUP_RECEIVED'
}