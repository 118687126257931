import './signup.scss';

import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Typography, TextField, Button } from '@material-ui/core'
import { Send as Send, Undo as Retry } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countries } from '../../../constants/countries';
import { makeStyles } from '@material-ui/core/styles';
import { DigitInputs } from './digitInputs';

import { createAcount, activateAcount } from '../../../services/authServices';
import { setDeviceId, setMobilePhone, setActivationCode } from '../../../actions/authActions';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles({
    option: {
      fontSize: 15,
      '&[aria-selected="true"]' : {
        background : 'rgba(114, 75, 156, 0.5)'
      },
      '&:hover' : {
        background : '#ccc'
      },
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
  });


export const SignupScreen = () => {
    const classes = useStyles();
    const { deviceId, mobilePhone, activationCode } = useSelector(state => ({ ...state.auth }));
    const dispatch = useDispatch();
    const history = useHistory();

    if (!deviceId) {
        dispatch(setDeviceId(uuidv4()));
    }

    const [country, setCountry] = useState(null);
    const [suffix, setSuffix] = useState('');
    const [phoneNr, setPhoneNr] = useState('');
    const [sent, setSent] = useState(false);
    const [validation, setValidation] = useState('');

    const changeCountry = (e,v) => {
        setCountry(v);
        setSuffix('');
        setPhoneNr('');
    }

    const handleSuffixChange = (event) => {
        setSuffix(event.target.value);
    };

    const handlePhoneChange = (event) => {
        setPhoneNr(event.target.value);
    };

    const Fix = (x) => {
        return x.replace(/^[\s|0]+|\s+$/gm,'').replace(/[^\d]/gm,'');        
    }

    const sendPhoneNumber = () => {
        //do some stuff
        if (phoneNr.length > 4) {            
            setValidation('');
            
            //first fix the phoneNr to internatial number
            let nr = country.idd.root;
            if (country.idd.suffixes.length == 1) nr += country.idd.suffixes[0];
            else if (country.idd.suffixes.length > 1) nr += Fix(suffix);
            nr += Fix(phoneNr);

            createAcount(nr, deviceId ).then(() => {
                dispatch(setMobilePhone(nr));
                setSent(true);
            }); 
        }
    }

    const sendValidationCode = () => {
        activateAcount( mobilePhone, deviceId, validation ).then(() => {
            dispatch(setActivationCode(validation));
            
            history.push("/readers");
        });  
    }    

    const handleDigitsChanged = (digits) => {
        setValidation(digits.asString);
    };

    const renderPhoneInput = () => <>
        <p>
            PLEASE PROVIDE US YOUR PHONE NUMBER. WE WILL SEND A TEXT-MESSAGE WITH A VALIDATION CODE TO YOUR PHONE.
        </p>
        
        <form noValidate autoComplete="off">
            <Autocomplete
                options={countries}
                size="small"
                autoHighlight
                getOptionLabel={(option) => `${option.flag} ${option.translations ? option.translations.nld.common : option.name.common}`}
                classes={{
                    option: classes.option,
                }}

                onChange={changeCountry}

                renderOption={(option) => (
                    <React.Fragment>
                        <span>{option.flag}</span>
                        {option.translations ? option.translations.nld.common : option.name.common}
                    </React.Fragment>
                )}

                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Kies uw land"
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'some-of-the-countries', //disabled auto complete
                        }}
                    />
                )}
            />

            {country != null ? <><div className="centeredFlex phoneInputs">
                <TextField
                    id="landcode"
                    label="&nbsp;"

                    value={`${country.flag} ${country.idd?.root}${country.idd?.suffixes.length === 1 ? country.idd?.suffixes[0] : ''}`}
                    margin={'normal'}

                    style={{width : '4em'}}

                    InputProps={{
                        readOnly: true,
                        shrink: true                                
                    }}                            
                />
                
                {country.idd?.suffixes.length > 1 &&
                    <TextField
                        id="standard-select-suffix"
                        style={{width : '4em'}}
                        label="area"
                        value={suffix}
                        margin={'normal'}
                        onChange={handleSuffixChange}>                                
                    </TextField>
                }

                <TextField
                    label="subscriber nr."
                    value={phoneNr}
                    onChange={handlePhoneChange}
                    margin={'normal'}                        
                />
            </div>
            <Button
                style={{ float : 'right' }}
                variant="contained"
                color="secondary"
                onClick={sendPhoneNumber}
                className="button">SEND &nbsp; <Send /></Button>
            </>
            
            : <p>
                PLEASE CHOOSE A COUNTRY
            </p>}                    
        </form>
    </>;

    const renderValidationInput = () => <>
        <p>
            A TEXT MESSAGE WITH YOUR VALIDATION CODE WAS SENT. PLEASE FILL IN THE BOXES BELOW.
        </p>

        <DigitInputs
            className="digits"
            onDigitsChange={handleDigitsChanged}
            numberOfDigits={6}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', //disables auto complete
                    }}
                />
            )}
        />

        <p>
            <Button
                style={{ float : 'right' }}
                variant="contained"
                color="secondary"
                onClick={sendValidationCode}
                className="button">SEND VALIDATION &nbsp; <Send />
            </Button>                    
        </p>
    </>;

    return (
        <div className="signup">
            <div className="signupForm">
                <Typography variant="h3" component="h2">
                    SIGN-UP
                </Typography>

                {sent ? renderValidationInput() : renderPhoneInput() }
            </div>
        </div>
    )
}