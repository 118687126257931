import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
  client_id: process.env.REACT_APP_implicit_client_id,
  redirect_uri: process.env.REACT_APP_redirect_uri,
  response_type: process.env.REACT_APP_response_type,
  scope: process.env.REACT_APP_scope,
  authority: process.env.REACT_APP_authority,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
  staleStateAge : 120
};

export default createUserManager(userManagerConfig);