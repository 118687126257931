import './mainMenu.scss';
import back from '../../../assets/images/back.jpg';
import chooseSpread from '../../../assets/images/choose-spread.png';
import addCredits from '../../../assets/images/add-credits.png';
import directContact from '../../../assets/images/directContact.png';
import conversations from '../../../assets/images/conversations.png';
import logo from '../../../assets/images/applogo.png';
import WhenAuth from '../../parts/auth/whenAuth';

import {
    Person as Profile
  } from '@material-ui/icons';

import {
    Button    
  } from '@material-ui/core';

import { Link, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";

export const MainMenu = () => {
    const displayName = useSelector(state => state.profile?.displayName);
    
    return (
        <div className="mainMenu">
            <h2>Welcome back, {displayName}</h2>
            {/* <img src={logo} /> */}
            <div className="br"></div>
            {(!window.consultantApp) && <>
            <Link to={{ pathname: '/spread/cardoftheday/TAROT', key: '/spread' }}>
                <Button className="mainMenuButton"
                        variant="contained"
                        color="primary">
                    <img src={back} />
                    Dagkaart
                </Button>
            </Link>
            <Link to={{ pathname: '/spreads', key: '/spreads' }}>
                <Button className="mainMenuButton"
                        variant="contained"
                        color="primary">
                    <img src={chooseSpread} />
                    Legging kiezen
                </Button>
            </Link>
            <div className="br"></div>
            <Link to={{ pathname: '/readers', key: '/readers' }}>
            <Button className="mainMenuButton"
                    variant="contained"
                    color="primary">
                <img src={directContact} />
                Start conversation
            </Button>
            </Link>
            </> }
            
            <WhenAuth>
                <>
                    <Link to={{ pathname: '/conversations', key: '/spreads' }}>
                        <Button className="mainMenuButton"
                                variant="contained"
                                color="primary">
                            <img src={conversations} />
                            Jouw gesprekken
                        </Button>
                    </Link>
                    <Link to={{ pathname: '/profile', key: '/profile' }}>
                        <Button className="mainMenuButton"
                                variant="contained"
                                color="primary">
                            <Profile color="secondary" />
                            Profile
                        </Button>
                    </Link>            
                </>

                {window.consultantApp ?
                    <Redirect to={`/login`} /> :
                    <Link to={{ pathname: '/signup', key: '/signup' }}>
                        <Button className="mainMenuButton"
                                variant="contained"
                                color="primary">
                            <img src={chooseSpread} />
                            Signup
                        </Button>
                    </Link>
                }
            </WhenAuth>            
        </div>
    )
}