import { actionTypes as ActionTypes } from './actionTypes';

export { ActionTypes }

export const setRedirectLocation = (location) => ({
    type: ActionTypes.SET_REDIRECTLOCATION,
    domain : ActionTypes.DOMAIN,
    location : location
});

export const setDeviceId = (deviceId) => ({
    type: ActionTypes.SET_DEVICE_ID,
    domain : ActionTypes.DOMAIN,
    deviceId : deviceId
});

export const setMobilePhone = (mobilePhone) => ({
    type: ActionTypes.SET_MOBILE_PHONE,
    domain : ActionTypes.DOMAIN,
    mobilePhone : mobilePhone
});

export const setActivationCode = (activationCode) => ({
    type: ActionTypes.SET_ACTIVATION_CODE,
    domain : ActionTypes.DOMAIN,
    activationCode : activationCode
});

export const invalidate = () => ({
    type : ActionTypes.INVALIDATE,
    domain : ActionTypes.DOMAIN,
});

export const setAccessToken = (accessToken, userId) => ({
    type : ActionTypes.SET_ACCESSTOKEN,
    domain : ActionTypes.DOMAIN,
    accessToken : accessToken,
    userId : userId
});

export const setOidcUser = (profile) => ({
    type : ActionTypes.SET_OIDC_USER,
    domain : ActionTypes.DOMAIN,
    profile : profile
});