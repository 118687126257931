import { ActionTypes } from '../actions/rtcActions';
import update from 'immutability-helper';
import * as sdpTransform from 'sdp-transform';

/* this module for now assumes peer 2 peer in a 1 on 1 method. No 1 to many etc. */

function startCall(state, action) {
    if (state.activeConversation?.id === action.conversationId) {
        return state;
    }

    action.deviceInfo.sort((a, b) => a.kind <= b.kind ? -1 : 1);

    return  {
        //this does not change
        activeConversation : {
            id: action.conversationId,
            useVideo : action.useVideo,            
            initiator : action.initiator,
            peers : {}
        },

        //this can change
        media : {
            mediaDevices : action.deviceInfo,
            stream : action.initialStream
        },

        callsWaiting : state.callsWaiting || {}
    };
}

function answerCall(state, action) {
    //set the activeconversation; removing the callswaiting
    //action.conversationId
    action.deviceInfo.sort((a, b) => a.kind <= b.kind ? -1 : 1);
    
    let newState = {
        //this does not change
        activeConversation : {
            id: action.conversationId,
            useVideo : action.useVideo,            
            initiator : action.initiator,
            peers : state.callsWaiting[action.conversationId].peers
        },

        //this can change
        media : {
            mediaDevices : action.deviceInfo,
            stream : action.initialStream
        },
        callsWaiting : update (state.callsWaiting, { $unset: [ action.conversationId ] })
    }
    
    return newState;
}

function handleReceivedSdp(state, action) {
    if (action.conversationId === state.activeConversation?.id) {
        let senderId = action.senderId;
        
        //presume sdp is always the first to arrive
        let newPeers = {...state.activeConversation.peers, [senderId] : { sdp : action.sdp, candidates : [], lastMsg : 'SDP' } };
        return update(state, {
            activeConversation : {
                peers : { $set : newPeers }
            }
        });
    }
    
    let description = sdpTransform.parse(action.sdp.sdp);
    let videoReq = description.media.filter(m => m.type === 'video')[0];
    let requestedVideo = videoReq?.direction === "sendrecv";
    
    //probably we can should create a call waiting
    if (!state.callsWaiting) {
        return {...state, callsWaiting : {
            [action.conversationId] : {
                id : action.conversationId,
                peers : {
                    [action.senderId] : {
                        senderDisplayName : action.senderDisplayName,
                        sdp : action.sdp,
                        requestedVideo : requestedVideo,
                        candidates : [],
                        lastMsg : 'SDP' }
                }
            }
        }}
    }
    
    return update(state, { callsWaiting : { [action.conversationId] : { $set : {
        id : action.conversationId,
        peers : {
            [action.senderId] : {
                senderDisplayName : action.senderDisplayName,
                sdp : action.sdp,
                requestedVideo : requestedVideo,
                candidates : [],
                lastMsg : 'SDP'
            }
        }
      }
     } }  } );    
}

function handleReceivedCandidate(state, action) {
    if (action.conversationId === state.activeConversation?.id) {
        let senderId = action.senderId;

        //presume sdp is always the first to arrive
        let peer = state.activeConversation.peers[senderId];
        let newPeer = { ...peer, candidates : [...peer.candidates, action.candidate], lastMsg : action.candidate };

        let newPeers = {...state.activeConversation.peers, [senderId] : newPeer };        
        return update(state, {
            activeConversation : {
                peers : { $set : newPeers }
            }
        });
    }
    
    //probably we can find a call waiting
    let callWaiting = state.callsWaiting[action.conversationId];
    if (!callWaiting) return state;
    
    let senderId = action.senderId;

    //presume sdp is always the first to arrive
    let peer = callWaiting.peers[senderId];
    let newPeer = { ...peer, candidates : [...peer.candidates, action.candidate], lastMsg : action.candidate };
    let newPeers = {...callWaiting.peers, [senderId] : newPeer };        

    return update(state, {
        callsWaiting : {
            [action.conversationId] : {
                peers : { $set : newPeers }
            }
        }
    });
}

function handleReceivedHangup(state, action) {
    if (action.conversationId === state.activeConversation?.id) {
        return {
            activeConversation : null,
            media : null,
            callsWaiting : state.callsWaiting || {}
        };
    }

    if (!state.callsWaiting || !state.callsWaiting[action.conversationId]) {
        return state;
    } 

    //else just remove from the calls waiting list
    return update(state, {
        callsWaiting : { $unset: [ action.conversationId ] }        
    })
}

function hangup(state, action) {
    return handleReceivedHangup(state, action);
}

export default function activeCallsReducer(state = {  }, action) {
    if (action.domain !== ActionTypes.DOMAIN)
    return state;

    switch (action.type) {
        case ActionTypes.RCV_RTC:
        case ActionTypes.WEB_RTC:
            switch (action.subType) {
                case ActionTypes.HANG_UP:
                    return hangup(state, action);
                case ActionTypes.RTC_SDP_RECEIVED :
                    return handleReceivedSdp(state, action);
                case ActionTypes.RTC_CANDIDATE_RECEIVED :
                    return handleReceivedCandidate(state, action);
                case ActionTypes.RTC_HANGUP_RECEIVED :
                    return handleReceivedHangup(state, action);
                default : return state;
            }

        case ActionTypes.START_CALL:
            return startCall(state, action);
        case ActionTypes.ANSWER_CALL:
            return answerCall(state, action);

        default : return state;
    }
}