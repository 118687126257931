import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import './qrcodes.scss';

class AppRedirectScreen extends Component {
    constructor() {
        super();        
    }
   
    animateExit() {
        
    }

    animateEnter() {
        
    }    

    componentWillReceiveProps(props) {
        if (props.animationState !== this.props.animationState) {
            if (this.props.animationState.indexOf("enter") > -1) {
                if (props.animationState.indexOf("exit") > -1) {
                    this.animateExit();
                }
            } else {
                if (props.animationState.indexOf("enter") > -1) {
                    this.animateEnter();
                }
            }
        }
    }
    
    render() {
        var iosLink = "https://apps.apple.com/nl/app/your-tarot-trek-een-tarot-dagkaart/id432059827";
        var androidLink = "https://play.google.com/store/apps/details?id=nl.livetarot.yourtarot&hl=nl";
        
        if (/Android/i.test(navigator.userAgent)) {
            return window.location.replace(androidLink);
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            return window.location.replace(iosLink);
        }

        return <div className="qrcodes">
            <div>
                <img class="banner" src="./images/YourTarotBanner.png" />
                <h2>Kies hieronder jouw store.</h2>
                <p>
                    Klik op de afbeelding of scan de qrcode om de app te installeren.
                </p>
            </div>
            <div class="links">
            <figure>
                <a href={iosLink}>
                    <QRCode value={iosLink} renderAs="svg" level="Q" includeMargin="true" size="256" />                
                    <img src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png" />
                </a>
            </figure>
            <figure>
                <a href={androidLink}>
                    <QRCode value={androidLink} renderAs="svg" level="Q" includeMargin="true" size="256" />
                    <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
                </a>
            </figure>
            </div>
        </div>;
    }
}



export default AppRedirectScreen;