import { ActionTypes } from '../actions/profileActions';

export default function profileReducer(state = {  }, action) {
    if (action.domain !== ActionTypes.DOMAIN)
        return state;

    switch (action.type) {
        case ActionTypes.SAVE_PROFILE:
            return {...action.profile, language : state.language || 'en' };

        case ActionTypes.SET_LANGUAGE:
            console.log("Setting language to: " + action.language);
            return { ...state, language : action.language }

        default:
            return state;
    }   
}