import update from 'immutability-helper';
import { ActionTypes } from '../actions/authActions';
import { v4 as uuidv4 } from 'uuid';

export default function authReducer(state = { deviceId : uuidv4() }, action) {
    if (action.domain !== ActionTypes.DOMAIN)
        return state;

    switch (action.type) {
        case ActionTypes.SET_REDIRECTLOCATION:
            return update(state, { redirectLocation : { $set : action.location } });

        case ActionTypes.SET_DEVICE_ID:
            return update(state, { deviceId : { $set : action.deviceId } } );

        case ActionTypes.SET_MOBILE_PHONE: {
            let newState = update(state, { mobilePhone : { $set : action.mobilePhone } } );
            console.log(["AUTHREDUCER", newState]);
            return newState;
        }            

        case ActionTypes.SET_ACCESSTOKEN:
            return update(state, { 
                accessToken : { $set : action.accessToken },
                userId : { $set : action.userId }
            } );

        case ActionTypes.SET_ACTIVATION_CODE:
            return update(state, { activationCode : { $set : action.activationCode } } );

        case ActionTypes.INVALIDATE:
            return update(state, { $unset : [ 'mobilePhone', 'activationCode' ] } );

        default:
            return state;
    }   
}