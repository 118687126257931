export const actionTypes = {
    DOMAIN : 'RTC_ACTIONS',  
    WEB_RTC : 'WEB_RTC',  
    RCV_RTC : 'RCV_RTC', 
    
    START_CALL : 'START_CALL',
    ANSWER_CALL : 'ANSWER_CALL',
    HANG_UP : 'HANG_UP',

    RTC_SDP_RECEIVED : 'RTC_SDP_RECEIVED',
    RTC_CANDIDATE_RECEIVED : 'RTC_CANDIDATE_RECEIVED',
    RTC_HANGUP_RECEIVED : 'RTC_HANGUP_RECEIVED'
}