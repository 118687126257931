import './readerLogin.scss';

import React from 'react';
import userManager from '../../../services/userManager';
import { User } from 'oidc-client';

import { userFound } from 'redux-oidc';
import { useDispatch } from "react-redux";

export const ReaderLogin = () => {
    const [implicitLogin, setImplicitLogin] = React.useState(null);
    const dispatch = useDispatch();

    const messageReceiver = (evt) => {
        if (process.env.REACT_APP_authority.indexOf(evt.origin) > -1) {
            if (evt.data && evt.data[0] === '#') {
                //do this only once!
                userManager.processSigninResponse(`http://localhost/callback${evt.data}`)
                .then(signinResponse => {           
                    let user = new User(signinResponse);
                    return userManager.storeUser(user).then(() => {
                        dispatch(userFound(user));                        
                    });
                }, () => {});                
            }
        }
    }

    React.useEffect(() => {
        userManager.createSigninRequest({ display : "justLogin" }).then(signinRequest => {
            setImplicitLogin(signinRequest);
        });

        window.addEventListener('message', messageReceiver);
        return () => {
            window.removeEventListener('message', messageReceiver);            
        }
    }, []);

    return <div className="readerlogin">
        <iframe src={ implicitLogin ? implicitLogin.url : "about:blank"} />
    </div>
}