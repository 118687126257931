import { actionTypes as ActionTypes } from './actionTypes';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export { ActionTypes }

export const setConsultsOverview = (consultsList) => ({
    type: ActionTypes.SET_CONSULTS_OVERVIEW,
    domain: ActionTypes.DOMAIN,
    consults: consultsList
});

export const expandConsultant = (consultant, visible) => ({
    type: ActionTypes.EXPAND_CONSULTANT,
    domain: ActionTypes.DOMAIN,
    consultant: consultant,
    visible: visible
});

export const selectConversation = (conversationId) => ({
    type: ActionTypes.SELECT_CONVERSATION,
    domain: ActionTypes.DOMAIN,
    conversationId: conversationId
});

export const addCardSpreadToConversation = (conversationId, spreadId, deckId, cards, decktypeId) => (
    {
        type: ActionTypes.ADD_SPREAD,
        domain: ActionTypes.DOMAIN,
        conversationId: conversationId,
        spreadId: spreadId,
        decktypeId: decktypeId,
        deckId: deckId,
        cards: cards
    });

export const saveDraft = (conversationId, draft) => ({
    type: ActionTypes.SAVE_DRAFT,
    domain: ActionTypes.DOMAIN,
    conversationId: conversationId,
    draft: draft
});

export const sendWebRTCSdp = (conversationId, sdp) => ({
    $type: "ChatMessages.SendWebRTCSdp, ChatMessages",    
    type : ActionTypes.WEB_RTC,
    domain: ActionTypes.DOMAIN,
    conversationId: conversationId,
    sdpType : sdp.type,
    sdp : sdp.sdp
});

export const sendWebRTCCandidate = (conversationId, candidate) => ({
    $type: "ChatMessages.SendWebRTCCandidate, ChatMessages",
    type : ActionTypes.WEB_RTC,
    domain: ActionTypes.DOMAIN,
    conversationId: conversationId,
    candidate: candidate.candidate,
    sdpMid: candidate.sdpMid,
    sdpMLineIndex: candidate.sdpMLineIndex,
    usernameFragment: candidate.usernameFragment,
});

export const sendWebRTCHangup = (conversationId) => ({
    $type: "ChatMessages.SendWebRTCHangup, ChatMessages",
    type : ActionTypes.WEB_RTC,
    domain: ActionTypes.DOMAIN,
    conversationId: conversationId    
});

export const historyReceived = (self, history) => ({
    type : ActionTypes.HISTORY_RECEIVED,
    domain: ActionTypes.DOMAIN,
    self : self,
    history : history
});

export const historicEventsReceived = (self, history) => ({
    type : ActionTypes.HISTORIC_EVENTS_RECEIVED,
    domain: ActionTypes.DOMAIN,
    self : self,
    history : history
});

export const updateChatsView = (allChats) => ({
    type : ActionTypes.UPDATE_CHATS_VIEW,
    domain: ActionTypes.DOMAIN,
    allChats : allChats
});


export const sendInvite = (conversationId, userId, displayName, consultantId, consultantDisplayName) => ({
    type : ActionTypes.SEND_INVITE,
    domain: ActionTypes.DOMAIN,
    conversationId : conversationId,
    consultantId : consultantId,
    userId : userId,
    displayName : displayName,
    consultantDisplayName : consultantDisplayName
});

//ANDSTE REFACTOR
export const sendDraft = (conversationId, draft) => {
    let messageType = draft.type || 'text';

    switch (messageType) {
        case 'spread':
        case 'cardspreadrequest':
        case 'text':
            {
                let identifier = uuidv4();
                draft.draftId = identifier;

                return {
                    type: ActionTypes.SEND_DRAFT,
                    domain: ActionTypes.DOMAIN,
                    conversationId: conversationId,
                    draft: draft
                }
            }
        case 'album':
            {
                let identifier = uuidv4();
                draft.draftId = identifier;

                return (dispatch) => {
                    //send intermediate message first; this way we let the user know we are doing something
                    dispatch({
                        type: ActionTypes.SEND_DRAFT,
                        domain: ActionTypes.DOMAIN,
                        conversationId: conversationId,
                        draft: {
                            draftId: draft.draftId,
                            type: "album-intermediate",
                            textMessage: draft.textMessage
                        }
                    });
                    
                    //for every file; request a sas url
                    let blobUrls = [];
                    let w = draft.files.length;
                    draft.files.forEach((f, i) => {
                        axios.get(`${process.env.REACT_APP_apiurl}uploadmedia/request-url/${identifier}/${i}`)
                        .then(res => {
                            blobUrls[i] = res.data.split("?")[0];
                            axios.put(
                                res.data,
                                f,
                                {
                                    headers: {
                                        "content-type": "image/jpeg",
                                        "x-ms-blob-type": "BlockBlob",
                                    }
                                })
                                .then(() => {
                                    w--;
                                    if (!w) {
                                        dispatch({
                                            type: ActionTypes.SEND_DRAFT,
                                            domain: ActionTypes.DOMAIN,
                                            conversationId: conversationId,
                                            draft: {
                                                draftId: draft.draftId,
                                                blobUrls : blobUrls,
                                                type: "album",
                                                textMessage: draft.textMessage
                                            }
                                        });
                                    }
                                })
                                .catch(error => { console.log(error); console.log('error here!!'); });
                        });

                    });
                }
            }
        case 'audio':
            {
                let identifier = uuidv4();
                let sasUrlPromise = axios.get(`${process.env.REACT_APP_apiurl}uploadmedia/request-url/${identifier}`)
                
                draft.draftId = identifier;
                //don't send just yet
                //upload audio first
                return (dispatch) => {
                    //send intermediate message first; this way we let the user know we are doing something
                    dispatch({
                        type: ActionTypes.SEND_DRAFT,
                        domain: ActionTypes.DOMAIN,
                        conversationId: conversationId,
                        draft: {
                            draftId: draft.draftId,
                            type: "audio-intermediate",
                            textMessage: draft.textMessage
                        }
                    });

                    //request SAS url
                    sasUrlPromise.then(res => {
                        axios.put(
                            res.data,
                            draft.recordedBlob.blob,
                            {
                                headers: {
                                    "content-type": draft.recordedBlob.blob.type,
                                    "x-ms-blob-type": "BlockBlob",
                                }
                            })
                            .then(() => {
                                dispatch({
                                    type: ActionTypes.SEND_DRAFT,
                                    domain: ActionTypes.DOMAIN,
                                    conversationId: conversationId,
                                    draft: {
                                        draftId: draft.draftId,
                                        blobUrl : res.data.split("?")[0],
                                        type: "audio",
                                        mimeType : draft.recordedBlob.blob.type,
                                        textMessage: draft.textMessage
                                    }
                                });
                            })
                            .catch(error => { console.log(error); console.log('error here!!'); });
                    });
                }
            }
        default: return { type: "NOMSG" };
    }
}

export const startConversation = (consultant, conversationId) => ({
    type: ActionTypes.START_CONVERSATION,
    domain: ActionTypes.DOMAIN,
    consultant: consultant,
    conversationId : conversationId
});

//external triggers:

export const msgReceived = (sub, evt) => ({
    type: ActionTypes.MESSAGE_RECEIVED,
    domain: ActionTypes.DOMAIN,
    sub: sub,
    chatEvent: evt    
});

export const textReceived = (sub, evt) => ({
    type: ActionTypes.TEXT_MESSAGE_RECEIVED,
    domain: ActionTypes.DOMAIN,
    sub: sub,
    chatEvent: evt
});

export const spreadReceived = (sub, evt) => ({
    type: ActionTypes.SPREAD_MESSAGE_RECEIVED,
    domain: ActionTypes.DOMAIN,
    sub: sub,
    chatEvent: evt
});

export const spreadRequestReceived = (sub, evt) => ({
    type: ActionTypes.SPREADREQUEST_MESSAGE_RECEIVED,
    domain: ActionTypes.DOMAIN,
    sub: sub,
    chatEvent: evt
});

export const invited = (sub, evt) => ({
    type: ActionTypes.INVITE_RECEIVED,
    domain: ActionTypes.DOMAIN,
    sub: sub,
    chatEvent: evt
});

export const audioIntermediateReceived = (sub, evt) => ({
    type: ActionTypes.AUDIO_INTERMEDIATE_MESSAGE_RECEIVED,
    domain: ActionTypes.DOMAIN,
    sub: sub,
    chatEvent: evt
});

export const audioReceived = (sub, evt) => ({
    type: ActionTypes.AUDIO_MESSAGE_RECEIVED,
    domain: ActionTypes.DOMAIN,
    sub: sub,
    chatEvent: evt
});

export const albumIntermediateReceived = (sub, evt) => ({
    type: ActionTypes.ALBUM_INTERMEDIATE_MESSAGE_RECEIVED,
    domain: ActionTypes.DOMAIN,
    sub: sub,
    chatEvent: evt
});

export const albumReceived = (sub, evt) => ({
    type: ActionTypes.ALBUM_MESSAGE_RECEIVED,
    domain: ActionTypes.DOMAIN,
    sub: sub,
    chatEvent: evt
});


export const sdpReceived = (sub, evt) => ({
    type: ActionTypes.RTC_SDP_RECEIVED,
    domain: ActionTypes.DOMAIN,
    sub: sub,
    senderId : evt.senderId,
    conversationId : evt.conversationId,
    sdp: {        
        type : evt.sdpType,
        sdp : evt.sdp
    }
});

export const candidateReceived = (sub, evt) => {
    let result =
    {
        type: ActionTypes.RTC_CANDIDATE_RECEIVED,
        domain: ActionTypes.DOMAIN,
    
        senderId : evt.senderId,
        conversationId : evt.conversationId,

        sub: sub,
        candidate: evt
    };

    delete evt.senderId;
    delete evt.conversationId;

    return result;
};

export const hangupReceived = (sub, evt) => {
    let result =
    {
        type: ActionTypes.RTC_HANGUP_RECEIVED,
        domain: ActionTypes.DOMAIN,
    
        senderId : evt.senderId,
        conversationId : evt.conversationId,

        sub: sub
    };

    delete evt.senderId;
    delete evt.conversationId;

    return result;
}