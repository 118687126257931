import '../assets/sass/app.scss';

import CssBaseline from '@material-ui/core/CssBaseline';

import { Navigation } from './shared/main/Navigation';
import { MainApplication } from './shared/main/MainApplication';
import ActiveCall from './screens/conversations/activeCall';
import { WaitingCalls } from './screens/conversations/waitingCalls';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { HashRouter as Router } from "react-router-dom";


function App () {
    const theme = 
          createMuiTheme({
            palette: {
                primary: { main: '#724B9C' }, //724B9C //9b24ac
                secondary: { main: '#ffde60' }, //ffde60
                type: 'light',
            },
          });

        
          theme.overrides = {
            MuiBottomNavigation: {
                root : {
                    backgroundColor : theme.palette.secondary.main,
                }
            },
            MuiBottomNavigationAction : {
                root : {
                    color : theme.palette.secondary.contrastText,
                    '&$selected' : {
                        color: theme.palette.primary.main,
                    }
                }
            }  
      };
    
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Navigation />
                <MainApplication />                
            </Router>
            <ActiveCall />
            <WaitingCalls />
        </ThemeProvider>
    );
}

export default App;