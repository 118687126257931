import { ActionTypes } from '../actions/authActions';

export default function oidcReducer(state = null, action) {
    if (action.domain !== ActionTypes.DOMAIN)
        return state;

    switch (action.type) {
        case ActionTypes.SET_OIDC_USER:
            return action.profile;

        default:
            return state;
    }   
}