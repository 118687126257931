import axios from "axios"

export const createAcount = (mobilePhone, deviceId) => {
    return axios.post(`${process.env.REACT_APP_IDP_Api}user/createByPhone/${mobilePhone}/${deviceId}`);    
}

export const activateAcount = (mobilePhone, deviceId, code) => {
    return axios.post(`${process.env.REACT_APP_IDP_Api}user/activatePhone/${mobilePhone}/${deviceId}/${code}`);    
}

