import { ActionTypes } from '../actions/chatActions';
import update from 'immutability-helper';

function getStateMessageFromEvent (msg, self) {
    let messageType = '';
    switch (msg.eventType) {
        case "INVITED" : return null;
        case "TEXTRECEIVED" : messageType = "text"; break;
        case "SPREADRECEIVED" : messageType = "spread"; break;
        case "SPREADREQUESTRECEIVED" : messageType = "cardspreadrequest"; break;
        case "AUDIOINTERMEDIATERECEIVED" : messageType = "audio-intermediate"; break;
        case "AUDIORECEIVED" : messageType = "audio"; break;
        case "ALBUMINTERMEDIATERECEIVED" : messageType = "album-intermediate"; break;
        case "ALBUMRECEIVED" : messageType = "album"; break;
        default : messageType = "text"; break;
    }

    let theMsg = {...msg};
            
    delete theMsg.aggregateId;
    delete theMsg.sender;
    delete theMsg.senderDisplayName;
    delete theMsg.eventType;

    theMsg.messageType = messageType;
    theMsg.side = self === msg.sender ? "from" : "to";

    return theMsg;    
}

function handleHistory(state, action) {
    let messages = state.messages || [];
    
    let audience = new Set(Object.keys(state.audience) || []);
    let firstSize = audience.size;
    let usersInConversation = {};

    let result = [];

    let messagesChanged = false;
    let stIdx = 0;
    let hIdx = 0;
    
    while (stIdx < messages.length && hIdx < action.history.length) {
        let dM = messages[stIdx].raised;
        let dH = action.history[hIdx].raised;

        if (messages[stIdx].identifier === action.history[hIdx].identifier &&
            dM === dH) {
            //same message
            result.push(messages[stIdx]);
            stIdx++;
            hIdx++;
        } else if (action.history[hIdx].eventType ==="INVITED" ) {
            //if we are dealing with an INVITE, it is not in messages, but in audience and imageUrl
            audience.add(action.history[hIdx].invitee);
            audience.add(action.history[hIdx].sender);
            
            usersInConversation[action.history[hIdx].invitee] = {
                userId : action.history[hIdx].invitee,
                displayName: action.history[hIdx].inviteeDisplayName,
                profileImage : action.history[hIdx].inviteeProfileImage,
            };

            usersInConversation[action.history[hIdx].sender] = {
                userId : action.history[hIdx].sender,
                displayName: action.history[hIdx].senderDisplayName,
                profileImage : action.history[hIdx].senderProfileImage,
            };

            hIdx++;
        } else {
            //check which one is before the other
            let theMsg = getStateMessageFromEvent(action.history[hIdx], action.self);
            if (dM <= dH) {
                result.push(messages[stIdx]);
                stIdx++;
            } else {
                messagesChanged = true;

                result.push(theMsg);
                hIdx++;
            }
        }
    }

    //do the rest of the messages
    if (stIdx < messages.length) {
        //is this even possible?
        result.push(messages[stIdx]);
        stIdx++;
    }

    while (hIdx < action.history.length) {
        if (action.history[hIdx].eventType ==="INVITED" ) {
            //if we are dealing with an INVITE, it is not in messages, but in audience and imageUrl
            audience.add(action.history[hIdx].invitee);
            audience.add(action.history[hIdx].sender);
            
            usersInConversation[action.history[hIdx].invitee] = {
                userId : action.history[hIdx].invitee,
                displayName: action.history[hIdx].inviteeDisplayName,
                profileImage : action.history[hIdx].inviteeProfileImage,
            };

            usersInConversation[action.history[hIdx].sender] = {
                userId : action.history[hIdx].sender,
                displayName: action.history[hIdx].senderDisplayName,
                profileImage : action.history[hIdx].senderProfileImage,
            };
        } else {
            let theMsg = getStateMessageFromEvent(action.history[hIdx], action.self);
            messagesChanged = true;            
            result.push(theMsg);
        }

        hIdx++;
    }

    if (audience.size > firstSize) {
        messagesChanged = true;
    }
    
    if (messagesChanged) {
        state = {
            messages : result,
            lastMessage : result.slice(-1)[0],
            audience : Object.assign(state.audience, usersInConversation)
        }
    }
    
    return state;
}

function processMessage(state, action) {
    let chatEvent = action.chatEvent;
    switch (action.chatEvent.eventType) {
        case 'TEXTRECEIVED' :
            {

                let msg = {
                    identifier: chatEvent.identifier,
                    message: chatEvent.message,
                    messageType: "text",
                    raised: chatEvent.raised,
                    side: chatEvent.sender === action.sub ? "from" : "to"
                };
                
                let result = state.messages.concat(msg);
                return { ...state, 
                    messages : result,
                    lastMessage : result.slice(-1)[0]
                }
            }
        case 'SPREADRECEIVED' :
            {

                let msg = {
                    identifier: chatEvent.identifier,
                    message: chatEvent.message,
                    messageType: "spread",
                    raised: chatEvent.raised,
                    cards: chatEvent.cards,
                    deckId : chatEvent.deckId,
                    decktypeId : chatEvent.decktypeId,
                    spreadId : chatEvent.spreadId,
                    side: chatEvent.sender === action.sub ? "from" : "to"
                };
                
                let result = state.messages.concat(msg);
                return { ...state, 
                    messages : result,
                    lastMessage : result.slice(-1)[0]
                }
            }
    }

    return state;
}

export default function chatReducer(state = {
    lastMessage : {},    
    messages : [],
    audience : {} }, action) {

    if (action.domain !== ActionTypes.DOMAIN)
        return state;

    switch (action.type) {
        case ActionTypes.HISTORIC_EVENTS_RECEIVED:
            return handleHistory(state, action);

        case ActionTypes.MESSAGE_RECEIVED:
            return processMessage(state, action);

        default : return state;
    }
}