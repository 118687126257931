import './profile.scss';

import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Typography, TextField, Button, Grid, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio } from '@material-ui/core'
import { SaveAlt as Save, Undo as Retry } from '@material-ui/icons';

import { saveProfile as saveProfileD } from '../../../actions/profileActions';

import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,    
    KeyboardDatePicker,
  } from '@material-ui/pickers';

export const ProfileScreen = () => {
    const [profile, setProfile] = useState( useSelector(state => ({ ...state.profile })) );
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile({
          ...profile,
          [name]: value,
        });
      };

    const handleBirthDateChange = (date) => {
        setProfile({
            ...profile,
            birthdate: date,
          });
    };

    const saveProfile = () => {
        dispatch(saveProfileD(profile));
    }
    
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="profile">
            <div className="profileForm">
                <Typography variant="h3" component="h2">
                    PROFILE
                </Typography>

                <p>ALL INFO YOU PROVIDE HERE IS OPTIONAL. THERE ARE NO MANDATORY FIELDS. THESE FIELD CAN BE USED BY OUR READERS TO SPEED UP YOU CONVERSATION</p>
                
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id="name-input"
                            name="name"
                            label="Name"
                            type="text"
                            fullWidth={true}
                            value={profile.name || ''}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="displayname-input"
                            name="displayName"
                            label="displayName"
                            type="text"
                            fullWidth={true}
                            value={profile.displayName || ''}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="email-input"
                            name="email"
                            label="E-Mail address"
                            type="email"
                            fullWidth={true}
                            value={profile.email || ''}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="DD-MM-yyyy"
                            margin="normal"
                            id="birthdate"
                            label="Birth date"
                            value={profile.birthdate  || new Date()}
                            onChange={handleBirthDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />

                    </Grid>
                    <Grid item xs={6}>

                    <p />
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Gender</FormLabel>
                            <RadioGroup aria-label="gender" name="gender" value={profile.gender || ''} onChange={handleInputChange}>
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                <FormControlLabel value="other" control={<Radio />} label="Other" />                                    
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                        
                    <Grid item xs={12} style={{ textAlign : "right" }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={saveProfile}
                            className="button">Save &nbsp; <Save />
                        </Button>
                    </Grid>                    
                </Grid>
                
            </div>
        </div>
        </MuiPickersUtilsProvider>
    )
}