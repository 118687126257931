import './reader.scss';
import conversate from '../../../assets/images/conversate.png'

function getCoordinatesForPercent(percent, r) {
    let piePart = percent / 100;

    const x = r * Math.cos(Math.PI / -2 + (2 * Math.PI * piePart));
    const y = r * Math.sin(Math.PI / -2 + (2 * Math.PI * piePart));
    
    return [x, y];
  }

function getAngels(number, radius, r) {
    let result = [];
    let scale = 1;

    let left = (1450 - 5 * scale * (68 + 8));
    left = radius - 50;

    for (let idx = 0; idx < 5; idx++) {
        let xCoord = scale * (68 + 8) * idx + left;
        let yCoord = - radius + 20;

        let f = 1;
        if (number > idx && number < idx + 1) {
            f = number - idx;
        }
        else if (number < idx + 1) {
            f = 0;
        }

        result.push( <g key={`angel-${idx}`} transform={`translate(${xCoord},${yCoord}) scale(${scale})`}>
            <rect clipPath={`url(#myClip-${r})`} className="whiteangel" x="-34" y="-34" width="68" height="68" />
            <rect clipPath={`url(#myClip-${r})`} className="color" x="-34" y="-34" width={f*68} height="68" />
            <g transform="translate(-35,0) scale(0.007,-0.007)">
                <path d="M4617,4890.9c-396.6-44.5-757.2-163.3-1022.3-337.2c-180.3-118.8-288.4-233.3-373.3-392.4c-48.8-91.2-57.3-131.5-57.3-296.9c0-165.4,8.5-205.7,59.4-309.7c67.9-142.1,243.9-316,318.2-316c89.1,0,163.3,76.4,163.3,167.6c0,61.5-14.8,93.3-74.2,150.6c-95.4,93.3-137.9,186.6-137.9,307.5c0,127.3,36.1,193,176,320.3c420,386,1376.6,504.8,2099.8,263c585.4-197.2,808.1-526,555.7-827.2c-38.2-46.7-78.5-106-84.8-129.4c-21.2-65.8,27.6-161.2,99.7-190.9c95.4-38.2,184.5,12.7,288.5,171.8c103.9,154.8,131.5,241.8,129.4,413.6c-2.1,451.8-511.2,844.2-1255.6,973.5C5259.7,4901.5,4844,4916.3,4617,4890.9z" />
                <path d="M4833.4,3766.7c-570.6-74.2-986.3-523.9-1016-1100.8c-23.3-422.1,159.1-795.4,496.3-1026.6c210-142.1,354.2-188.8,623.6-199.4c366.9-14.9,623.6,84.8,878.1,343.6c288.4,290.6,400.9,666,318.1,1056.3C6008.4,3438,5435.7,3845.2,4833.4,3766.7z M5329.7,3368c755.1-354.2,613-1444.4-207.9-1595c-263-48.8-511.2,31.8-719,229.1c-265.1,250.3-335.1,651.2-169.7,977.8c63.6,127.3,243.9,313.9,358.5,373.3C4812.1,3467.7,5104.8,3474,5329.7,3368z" />
                <path d="M2627.5,1881.2c-328.8-87-583.3-265.1-778.4-540.9c-231.2-322.4-343.6-689.3-519.6-1677.7c-112.4-629.9-178.2-922.6-269.4-1194.1c-173.9-523.9-426.3-882.4-786.9-1113.5c-216.3-140-227-218.5-48.8-379.7c485.7-436.9,1404.1-218.5,1928,460.3c129.4,167.5,258.8,422.1,258.8,509c0,140-178.2,216.3-269.4,114.5c-23.3-27.6-67.9-103.9-99.7-169.7c-256.6-540.9-926.9-916.3-1385-780.5l-87,27.6l186.6,182.4c475.1,468.7,676.6,986.3,912,2345.8C1838.5,653.1,1970,1017.9,2250,1300c205.7,205.7,451.8,299.1,742.4,280c205.7-12.7,381.8-99.7,591.8-290.6c178.2-163.3,227-180.3,320.3-106.1c42.4,31.8,55.1,61.5,55.1,123c0,67.9-17,95.4-131.5,207.9c-267.2,260.9-528.1,383.9-854.8,400.9C2826.9,1921.5,2746.3,1915.1,2627.5,1881.2z" />
                <path d="M6886.5,1910.9c-254.5-50.9-543-214.2-738.1-420c-133.6-140-148.5-233.3-55.2-307.5c91.2-72.1,142.1-57.3,299.1,84.8c258.8,235.4,436.9,316,693.6,316c341.5-2.1,653.3-190.9,848.4-517.5c169.7-282.1,256.6-591.8,413.6-1486.8c123-685.1,220.6-1086,341.5-1395.6c152.7-392.4,373.3-723.3,625.7-937.5c133.6-114.5,131.5-120.9-57.3-157c-299.1-55.1-702,101.8-994.7,388.2c-150.6,148.5-229.1,256.6-326.7,456c-74.2,148.5-99.7,171.8-193,171.8c-67.9,0-157-89.1-157-156.9c0-140,214.2-485.7,432.7-702.1c564.2-557.8,1395.6-663.9,1794.4-229.1c133.6,142.1,118.8,203.6-89.1,339.4c-371.2,243.9-625.7,613-799.6,1155.9c-84.8,267.2-150.6,572.7-256.6,1172.9C8526,485.5,8430.6,837.6,8269.4,1149.4c-248.2,475.1-651.2,744.5-1139,763.6C7026.5,1917.2,6916.2,1915.1,6886.5,1910.9z" />
                <path d="M4776.1,1263.9c-640.6-97.6-1162.3-598.1-1353.2-1302.3c-65.8-248.2-80.6-462.4-74.2-1242.9c4.2-651.2-2.1-808.1-31.8-1001.1c-55.1-326.6-127.3-566.3-250.3-820.8c-97.6-197.3-133.6-246-307.5-420c-216.3-214.2-231.2-248.2-150.6-358.5c176-237.6,655.4-515.4,1128.4-653.3c413.6-118.8,651.2-148.5,1251.4-148.5c483.6,0,585.4,6.4,827.2,50.9c602.4,106.1,1113.5,333,1440.2,638.4c182.4,169.7,193,260.9,44.5,403c-299.1,286.3-543,810.2-651.2,1397.8c-40.3,222.7-44.5,313.9-40.3,946c2.1,411.5-6.4,782.7-21.2,901.4c-76.4,649-445.4,1211.1-962.9,1465.6C5348.8,1253.3,5047.6,1306.4,4776.1,1263.9z M5179.1,924.6c557.8-101.8,1018.1-668.1,1081.7-1329.9c8.5-78.5,17-490,19.1-916.3c4.2-708.4,8.5-793.2,53-1003.2c110.3-538.7,282.1-939.6,555.7-1300.2c120.9-161.2,123-157-72.1-288.5c-483.6-328.8-1242.9-485.7-2114.7-439.1c-634.2,36.1-1175,190.9-1571.7,453.9L2986-3801l112.4,133.6c294.8,347.8,475.1,806,553.6,1410.5c33.9,246,44.5,1020.2,19.1,1183.5c-21.2,144.2,29.7,789,76.4,960.8C3946.8,604.3,4551.3,1041.2,5179.1,924.6z" />
                <path d="M2744.2-2363c-19.1-12.7-63.6-82.7-97.6-157c-112.4-246-294.8-392.4-583.3-466.6c-70-17-142.1-46.7-165.4-65.7c-50.9-46.7-48.8-159.1,2.1-222.7c33.9-42.4,59.4-50.9,137.9-46.7c220.6,8.5,528.1,159.1,710.5,347.8c106,110.3,241.8,373.3,241.8,468.8C2990.2-2386.3,2846-2303.6,2744.2-2363z"        id="path16" />
                <path d="M7085.9-2388.4c-78.5-70-74.2-148.5,19.1-339.4c135.8-273.6,375.4-466.6,699.9-560c190.9-55.1,271.5-50.9,322.4,21.2c44.5,63.6,40.3,169.7-6.4,214.2c-23.3,19.1-95.4,48.8-165.4,67.9c-290.6,74.2-464.5,210-574.8,447.5C7285.2-2331.2,7202.5-2288.7,7085.9-2388.4z" />
            </g>
        </g>);
    }

    return result;
}

export const ReaderLarge = ({children, ...consultant}) => {
    let r = Math.random();
    let radius = 210;
    let iconRadius = 50;
    let iconPadding = 15;

    let imgSrc = consultant.profileImage;
    let bannerSrc = consultant.coverPhoto;
    
    let topcoord = getCoordinatesForPercent(30, radius);
    let centercoord = getCoordinatesForPercent(32.5, radius);
    let bottomcoord = getCoordinatesForPercent(35, radius);
    let br = (bottomcoord[1] - topcoord[1]) / 2;
    
    return (
        <div className="readerbanner" >
            <div className="bgImage" style={{ backgroundImage : `url(${bannerSrc})` }}></div>

            <svg  className="readericon" viewBox="-250 -250 1600 480">
                <clipPath id={`myClip-${r}`}>    
                    <path transform="translate(-35,-35) scale(0.07,0.07)" d="m 286.60586,767.17703 -41.52395,48.03146 -41.3994,10.07496 -41.83587,-29.64009 -87.95732,26.97851 -43.764827,-20.92739 89.528617,-114.71861 58.43643,-264.45774 51.21054,-70.23489 75.55623,-14.58834 73.32003,43.60455 44.88094,45.01108 50.69457,-24.14678 3.51875,-48.36071 -66.13174,-49.08398 -8.77055,-72.1944 10.52688,-28.58539 46.5354,-40.25634 70.54204,-5.95773 60.14659,36.45347 4.99723,105.2954 -30.94288,28.78907 -31.09975,21.94413 -2.20957,50.71648 42.89922,27.49554 48.68712,-43.39104 58.30478,-43.4036 82.49583,11.30693 65.83009,71.62481 66.34128,303.08971 79.56086,73.73514 -71.76144,12.29205 -75.34283,-18.57162 -38.24045,33.92807 -64.96876,-60.51698 -62.25582,-3.33779 57.01948,131.95889 -146.46112,64.2769 -179.09078,-2.56775 -117.84259,-67.43981 69.27575,-105.77655 z" />                
                </clipPath>
                <clipPath id={`clipCircle-${r}`}>
                    <circle cx="0" cy="0" r={radius}  />
                </clipPath>

                <g className={`availabilityBanner ${consultant.profileStatus || "NA"}`}>
                    <path d={`m ${topcoord[0]} ${topcoord[1]} H ${topcoord[0] + 400}
                                a ${br / 2} ${br / 2} 0 0 1 ${br / 2} ${br / 2}
                                V ${bottomcoord[1] - br / 2}
                                a ${br / 2} ${br / 2} 0 0 1 -${br / 2} ${br / 2}
                                H ${bottomcoord[0]}`} />
                    <text x={centercoord[0] + 225} y={centercoord[1]}>{consultant.profileStatus || "NA"}</text>                    
                </g>

                <g clipPath={`url(#clipCircle-${r})`}>
                    <image                
                        preserveAspectRatio="xMidYMid meet"
                        xlinkHref={imgSrc}
                        x={-radius}
                        y={-radius}
                        width={2 * radius}
                        height={2 * radius}                
                        />                
                </g>

                <circle cx="0" cy="0" r={radius} stroke="black" strokeWidth="12" fill="transparent" />
                <circle cx="0" cy="0" r={radius} className={`inner ${consultant.profileStatus || "NA"}`} strokeWidth="10" fill="transparent" />

                <circle cx={centercoord[0]} cy={centercoord[1]} r={iconRadius} className={`iconCircle ${consultant.profileStatus || "NA"}`} />
                <image                
                        preserveAspectRatio="xMidYMid meet"
                        xlinkHref={conversate}
                        x={centercoord[0] - iconRadius + iconPadding}
                        y={centercoord[1] - iconRadius + iconPadding}
                        width={2 * (iconRadius - iconPadding)}
                        height={2 * (iconRadius - iconPadding)}                
                        />

                { getAngels(consultant.rating || 2.5, radius, r) }
            </svg>

            <h2>{consultant.displayName}</h2>
            <h3><q>{consultant.quote || "Pure & Professional"}</q></h3>

            <div className="readerchildren">
                {children}
            </div>
        </div>);
}