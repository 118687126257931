import './spread.scss';
import React from 'react';
import { useParams } from "react-router-dom";
import { Decks } from '../../../constants/decks';
import { Table } from '../../parts/spread/Table';

import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { SendTwoTone as Send } from '@material-ui/icons';

import useDimensions from "react-cool-dimensions";
import { getConsultants } from "../../../services/consultants";
import { Link, useHistory } from 'react-router-dom';

import { v5 as uuidv5 } from 'uuid';
import { useSelector, useDispatch } from "react-redux";

import { sendInvite, sendDraft } from '../../../actions/chatActions';

import { ReaderSmall } from '../../parts/reader/small';

export const SpreadScreen = (props) => {
    const { spread, decktype } = useParams();
    const [cardpositions, setCardpositions] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [consultants, setConsultants] = React.useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
    const {userId, displayName} = useSelector(state => ({ userId : state.auth.userId, displayName : state.profile.displayName }));

    React.useEffect(() => {
        getConsultants().then(x => {
            setConsultants(x.data);
        });
    }, []);
    
    let deckList = Object.keys(Decks);
    let deckKey = deckList.filter(x => Decks[x].deckType.indexOf(decktype) > -1)[0];
    
    function selectCard(card) {
        //first check positions, if allowed        
        setCardpositions(cardpositions.concat([card]));        
    }
    const { ref, width, height } = useDimensions();

    const openConsultantsMenu = (evt) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(evt.currentTarget);
        }
    };

    const SendSpreadToConcultant = (consultant) => {
        if (userId) {
            let conversationId = uuidv5(userId, consultant.userId);
        
            //dispatch the invite
            dispatch(sendInvite(conversationId, userId, displayName, consultant.userId, consultant.displayName));
            
            //also send the spread
            dispatch(sendDraft(conversationId, {
                type: "spread",
                conversationId : conversationId,
                textMessage: '',
                cards: cardpositions.map(cp => cp.filename),
                deckId: deckKey,
                decktypeId: decktype,
                spreadId: spread
            }));
            
            history.push("/conversation/" + conversationId);
          } else {
            //redirect to signup (TODO set some draft in the state)
            history.push("/signup");
          }
    }

    return (
        <div className="spreadscreen">
            <div className="spread" >
                <div className="cardsPart" ref={ref}>
                    <Table
                        deckType={decktype}                
                        deck={deckKey}
                        spread={spread}

                        cardPositions={cardpositions}
                        onSelectCard={(c) => selectCard(c)}

                        width={width}
                        height={height}
                        showReaderAids={false}
                        showHand={!anchorEl}
                    />
                </div>
                <div className={`consultants ${!!anchorEl ? 'open' : 'closed'}`}>
                    {consultants.map(c => <ReaderSmall
                        onClick={() => SendSpreadToConcultant(c)}
                        consultant={{ name: c.displayName, profileImage: c.profileImage}} />)}
                </div>

                {/* 

                <div class={`consultantselector ${!!anchorEl ? 'open' : 'closed'}`}>
                    
                   {consultants.map(c => <ReaderList
                            key={c.userId}
                            profileStatus={c.profileStatus || "NA"}
                            profileImage={c.profileImage}
                            rating={c.rating || 2.5}
                            baseList={true}
                            >
                        <div className="readerInformation" onClick={() => SendSpreadToConcultant(c)}>
                            <h2>{c.displayName} <span className="price">€ 1,50 /min</span></h2>
                            <ul>
                                {c.languages?.map(l => <li key={l}>{l}</li>)}                    
                            </ul>                       
                        </div>                        
                    </ReaderList>)}


                </div> */}
            </div>
            <div className="actions">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={openConsultantsMenu}
                    endIcon={<Send />}>
                    Legging insturen
                </Button>
            </div>            
        </div>
    )
}