import { DeckTypes } from './deckTypes';

export const Decks = {
    vibrant : {
        id : 'vibrant',
        directory: "./images/Rider-Waite/",
        extension: "jpg",
        deckType: [DeckTypes.TAROT, DeckTypes.TAROT_MAYOR_ARCANA]
    },
    comic : {
        id : 'comic',
        directory: "./images/Rider-Waite-2/",
        extension: "jpg",
        deckType: [DeckTypes.TAROT, DeckTypes.TAROT_MAYOR_ARCANA]
    },
    classic : {
        id : 'classic',
        directory : './images/Rider-Waite-Classic/',
        extension: "jpg",
        deckType: [DeckTypes.TAROT, DeckTypes.TAROT_MAYOR_ARCANA]
    },
    voyager : {
        id : 'voyager',
        directory : './images/Voyager/',
        extension: "jpg",
        deckType: [DeckTypes.VOYAGER],
        border : "#d6df90"
    },
    shadowscapes : {
        id : 'shadowscapes',
        directory: "./images/ShadowScapes/",
        extension: "jpg",
        deckType: [DeckTypes.TAROT, DeckTypes.TAROT_MAYOR_ARCANA]
    },
    gilded_reverie : {
        id : 'gilded_reverie',
        directory: "./images/GildedReverie/",
        extension: "jpg",
        deckType: [DeckTypes.LENORMAND, DeckTypes.EXTENDED_LENORMAND],
        fontcolor: "white",
        border : "black"
    }
}

//🜂 fire  ♈ ♌ ♐           
//🜄 water ♋ ♏ ♓
//🜁 air ♊ ♎ ♒
//🜃 earth ♉ ♍ ♑
//https://angelorum.co/tarot-card-meanings/tarot-card-meanings-suit-of-wands/queen-of-wands/
//iets met de decans: https://darkstarastrology.com/horoscope-decans/
//http://tarotheaven.com/king-of-cups.html
//http://lunarcafe.com/tarot/tarot-cards/the-suit-of-cups/king-of-cups/


//maar deze zijn anders: https://labyrinthos.co/blogs/learn-tarot-with-labyrinthos-academy/tarot-court-card-elements-and-zodiac-signs-correspondences-infographic