import { applyMiddleware, compose, createStore } from 'redux'
import createRootReducer from './reducers'
import thunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import SignalRChatMiddleWare from './reducers/SignalRChatMiddleWare';

import userManager from './services/userManager';
import createOidcMiddleware from 'redux-oidc';

const persistConfig = {
    key: '24ReadingsStorage',
    storage,
    blacklist: [ 'rtc', 'oidcUser' ]
}
const persistedReducer = persistReducer(persistConfig, createRootReducer());

export default () => {
    let store = createStore(
        persistedReducer, // root reducer with router state        
        compose(
            applyMiddleware(
                thunk,
                createOidcMiddleware(userManager),
                SignalRChatMiddleWare
            ),
        ),
    );    
    
    let persistor = persistStore(store);
    
    return { store, persistor };
}