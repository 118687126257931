import {
    JsonHubProtocol,
    HttpTransportType,
    HubConnectionBuilder,
    LogLevel
} from '@aspnet/signalr';

import { USER_FOUND, USER_EXPIRED } from 'redux-oidc'
import { REHYDRATE } from 'redux-persist/es/constants'
import axios from 'axios'
import { ActionTypes as AuthAt, setAccessToken, invalidate, setOidcUser } from '../actions/authActions'
import { ActionTypes as RtcAt, sdpReceived, candidateReceived, hangupReceived } from '../actions/rtcActions'
import { historyReceived, msgReceived, ActionTypes as ChatAt } from '../actions/chatActions';


const getSubFromAccessToken = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    var payload = JSON.parse(jsonPayload);
    return payload.sub;
}

const transformRequest = (jsonData = {}) =>
    Object.entries(jsonData)
        .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
        .join('&');



export default function SignalRChatMiddleWare( { dispatch, getState} ) {
    //variable to store the hub connection
    let connection;
    let connected = false;
    let connecting = false;
    let actionsWaiting = [];    
    
    const validateUserWhenPresent = (auth) => {
        if (!auth) return;
        if (!(auth.activationCode && auth.deviceId && auth.mobilePhone)) return;

        //now we need to check if this user is valid
        let cfg = {
            transformRequest: jsonData => transformRequest(jsonData),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        };
        let body = {
            grant_type: 'password',
            client_id: process.env.REACT_APP_client_id,
            client_secret: process.env.REACT_APP_client_secret,

            deviceId: auth.deviceId,
            username: auth.mobilePhone,
            password: auth.activationCode
        }

        axios.post(process.env.REACT_APP_token_end_point, body, cfg)
            .then((result) => {
                let accesstoken = result.data.access_token;
                let userId = getSubFromAccessToken(accesstoken);

                dispatch(setAccessToken(accesstoken, userId));
            })
            .catch(err => {
                if (err.response) {
                    dispatch(invalidate());
                }
            });
    }

    
    
    let receivedActions = {};    
    let debouceTimer;
    let debounceIsHit = false;
    
    const receivedAction = (dispatch, userId, cmd) => {
        if (debounceIsHit) {
            dispatch(msgReceived(userId, cmd));
        } else {
            if (!receivedActions[cmd.aggregateId]) receivedActions[cmd.aggregateId] = [];
            receivedActions[cmd.aggregateId].push(cmd);
            
            clearTimeout(debouceTimer);
            debouceTimer = setTimeout(() => {
                debounceIsHit = true;
                dispatch(historyReceived(userId, receivedActions));

                receivedActions = {};
            }, 300);
        }
    }

    const handleWebRTC = (state, action, connection) => {
        if (connected) {
            connection.invoke("SendRTCCommand", action);
        } else {
            actionsWaiting.push( () => connection.invoke("SendRTCCommand", action));
        }
    }
    
    const receivedRtcCommand = (dispatch, userId, cmd) => {
        if (cmd.senderId !== userId) {
            //console.log(cmd);
            if (cmd.sdpType) {        
                dispatch(sdpReceived(cmd));
            } else if (cmd.hasOwnProperty('candidate')) {
                dispatch(candidateReceived(cmd));
            } else {
                dispatch(hangupReceived(cmd));
            }
        }
    }

    const getCommandFromDraft = (conversationId, userId, displayName, draft) => {
        let messageType = draft.type || 'text';
        let identifier = Math.floor(Math.random() * 10000);

        switch (messageType) {
            case 'text':
                return {
                    $type: "ChatMessages.SendTextMessage, ChatMessages",
                    AggregateId: conversationId,
                    Identifier: identifier,
                    Sender: userId,
                    SenderDisplayName: displayName,
                    Message: draft.textMessage
                };

            case 'spread':
                return {
                    $type : "ChatMessages.SendSpreadMessage, ChatMessages",
                    AggregateId: conversationId,
                    Identifier: identifier,
                    Sender: userId,
                    SenderDisplayName: displayName,
                    Message: draft.textMessage,
                    cards: draft.cards,
                    deckId: draft.deckId,
                    decktypeId: draft.decktypeId,
                    spreadId: draft.spreadId
                }
        }
    };

    const handleChatCommand = (state, action, connection) => {
        switch (action.type) {
            case ChatAt.SEND_INVITE: {
                //first check if we are already part of conversation; no need to send invites all the time.
                if (!state.chats[action.conversationId]) {
                    connection.invoke("SendChatCommand", {
                        $type: "ChatMessages.Invite, ChatMessages",
                        AggregateId: action.conversationId,
                        Identifier: Math.floor(Math.random() * 10000),
                        Sender: action.userId,
                        SenderDisplayName: action.displayName,
                        Invitee: action.consultantId,
                        inviteeDisplayName: action.consultantDisplayName
                    });
                }
                break;
            }

            case ChatAt.SEND_DRAFT: {
                let displayName = state.profile.displayName;
                let userId = state.auth.userId;

                let cmd = getCommandFromDraft(action.conversationId, userId, displayName, action.draft);
                if (cmd) {
                    connection.invoke("SendChatCommand", cmd);
                    action.draft.identifier = cmd.Identifier;
                }

                break;
            }            
        }        
    }

    const setupSignalR = (userId, accessToken) => {
        //establish the SignalR connection
        if (connecting) return;
        connecting = true;

        let hubUrl = process.env.REACT_APP_huburl + '?access_token=' + accessToken;
        connection = new HubConnectionBuilder().withUrl(hubUrl).build();

        connection.on("OnChatEvent", cmd => receivedAction(dispatch, userId, cmd));
        connection.on("RtcCommand", cmd => receivedRtcCommand(dispatch, userId, cmd));

        connection
            .start()
            .then(() => {
                //when the connection is set up, get all messages
                //todo: set dt of last received messages
                let dt = null;
                connected = true;
                connecting = false;
                connection.invoke("SyncMessages", dt);
                
                //run all waiting actions in order
                actionsWaiting.forEach(a => a());
            })
            .catch(err => console.log(['Error while establishing connection :(', err]));
    }

    return next => async (action) => {
        if (action.type === REHYDRATE && action.payload) {
            //always remove the validated property from auth
            //we want to check that again now
            if (action.payload?.oidcUser) {
                delete action.payload.oidcUser;
                delete action.payload.auth;
            }

            if (window.consultantApp) {
                delete action.payload.auth;                
            }

            if (action.payload?.auth?.accessToken) {
                delete action.payload.auth.accessToken;
                delete action.payload.auth.userId;
            }

            //first test if we have a user:
            if (action.payload?.auth) validateUserWhenPresent(action.payload.auth);
        }

        if (action.type == USER_FOUND) {            
            if (getState().profile?.sub !== action.payload.profile.sub) {
                if (connected) {
                    connection.stop();
                    connected = false;
                }

                dispatch(setOidcUser(action.payload.profile));
                dispatch(setAccessToken(action.payload.access_token, action.payload.profile.sub));
            }
        }

        if (action.type === AuthAt.SET_ACCESSTOKEN) {
            setupSignalR(action.userId, action.accessToken);
        }

        if (action.type === RtcAt.WEB_RTC) {
            handleWebRTC(getState(), action, connection);
        }

        if (action.domain === ChatAt.DOMAIN) {
            handleChatCommand(getState(), action, connection);            
        }

        if (action.domain === AuthAt.DOMAIN) {
            if (action.type === AuthAt.SET_ACTIVATION_CODE) {
                //auto login                
                validateUserWhenPresent({...getState().auth, activationCode : action.activationCode });
            }
        }

        return next(action);
    }
}