import './waitingCalls.scss';

import React, { Component } from 'react';
import { useState, useEffect, createRef } from 'react';
import useDimensions from "react-cool-dimensions";

import { sendWebRTCSdp, sendWebRTCCandidate, sendWebRTCHangup, answerCall } from '../../../actions/rtcActions'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import {
  PermPhoneMsgTwoTone as Call,
  VideocamTwoTone as VideoCall,
  CallEndTwoTone as HangUpIcon } from '@material-ui/icons';

import caller from '../../../assets/images/caller.svg';

import { red, green } from '@material-ui/core/colors';

const RedButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[700]),
      backgroundColor: red[700],
      '&:hover': {
        backgroundColor: red[900],
      },
    },
  }))(Button);

  const GreenButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(green[700]),
      backgroundColor: green[700],
      '&:hover': {
        backgroundColor: green[900],
      },
    },
  }))(Button);


export const WaitingCalls = () => {
    const { callsWaiting, activeConversation } = useSelector(state => ({
        callsWaiting : state.rtc.callsWaiting,
        activeConversation : !!state.rtc.activeConversation?.id
    }));

    const dispatch = useDispatch();

    let takeCall = (conversationId, withVideo) => {  
        dispatch(answerCall(conversationId, withVideo));
    }

    let declineCall = (conversationId) => {
        dispatch(sendWebRTCHangup(conversationId));
    }

    return (<div id="callsWaiting" className={Object.keys(callsWaiting || {}).length > 0 && !activeConversation ? 'active' : ''}>
        <h1>Incoming call</h1>
        <ul>
          
        {Object.keys(callsWaiting || {}).map((cId) => <li key={cId}>
            <ul>
              <li><img src={caller} /></li>              
              {Object.keys(callsWaiting[cId].peers || {}).map(senderId => <li key={senderId}>
                      <h2>{callsWaiting[cId].peers[senderId].senderDisplayName || senderId}</h2>
                  </li>
              )}
            </ul>

            <div className="actionButtons">
              <div>
              <GreenButton
                      onClick={(e) => takeCall(cId, false)}
                      startIcon={<Call />}
                      variant="contained">
                  Take call</GreenButton>
              
              {!!Object.keys(callsWaiting[cId].peers || {}).filter(p => callsWaiting[cId].peers[p].requestedVideo)[0] && <>
                  <GreenButton
                          onClick={(e) => takeCall(cId, true)}
                          startIcon={<VideoCall />}
                          variant="contained">
                      Take video call</GreenButton>
              </>}
              </div>

              <RedButton
                      onClick={(e) => declineCall(cId)}
                      startIcon={<HangUpIcon />}
                      variant="contained">
                  Decline call</RedButton>
          </div>            
        </li>)}
        </ul>
    </div>);
}