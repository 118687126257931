import './conversations.scss';
import React from 'react';
import { getConsultant } from "../../../services/consultants";

import { Link } from 'react-router-dom';

import { ListItem, Badge, IconButton } from '@material-ui/core';
import {
  PermPhoneMsgTwoTone as Call,
  VideocamTwoTone as VideoCall,
  QuestionAnswerTwoTone as Messages,
  QuestionAnswerTwoTone as PrevMessages,
  PeopleAltTwoTone as Group,
  PersonOutlineTwoTone as Person
} from '@material-ui/icons';

import { startCall } from '../../../actions/rtcActions';

import { useSelector, useDispatch, shallowEqual } from "react-redux";

const mapState = state => ({
  overview: state.chats.overview,
  self : state.chats.self
});

export const ConversationsScreen = () => {
    const [visibleAudiences, setVisibleAudiences] = React.useState([]);
    const { self, overview } = useSelector(mapState, shallowEqual);
    const dispatch = useDispatch();
    
    /* Run this side effect everytime the overview changes 
    React.useEffect(() => {
        //  
    }, []);
    */

    const call = (e, conversationId) => {
      dispatch(startCall(conversationId, false));
      e.preventDefault();
    }

    const videoCall = (e, conversationId) => {
      dispatch(startCall(conversationId, true));
      e.preventDefault();
    }

    const toggleConversations = (e, audienceKey) => {
      setVisibleAudiences({...visibleAudiences, [audienceKey]: !visibleAudiences[audienceKey] });
      e.preventDefault();
    }

    const renderCenversation = (self, audience) => {
      const RenderLink = (conversationId) => {
        let to = `/conversation/${conversationId}`;
        let key = "/conversation";
        
        return React.forwardRef((itemProps, ref) => <Link to={{ pathname: to, key: key }} ref={ref} {...itemProps} />)        
      };
    
      let participants = Object.keys(audience.audience).filter(x => x !== self);
      let lastMessages = audience.lastMessages.filter(x => !!x.identifier);
      if (lastMessages.length == 0) return null;
    
      let lastMessage = lastMessages[0];
    
      let audienceIcon =  <Person />;
      let audienceTitle = <strong>--</strong>;
      if (participants.length > 0) {
        if (participants.length === 1) {
          if (!!participants[0]) {
            let profile = audience.audience[participants[0]];
            if (profile.profileImage) {
              //doe iets
              audienceIcon = <img src={profile.profileImage} />
            }
            audienceTitle = <strong>{profile.displayName}</strong>;
          }    
        } else {
          audienceIcon = <Group />;
          audienceTitle = <ul>{participants.map(p => <li key={p}>{audience.audience[p].displayName}</li>)}</ul>;
        }
      }
    
      return <li key={audience.key}>
        <ListItem button component={RenderLink(lastMessage.conversationId)} className="audienceListItem">
          <div className="audienceTitle">
            {audienceIcon}
            
            <div className="lastMessage">
              <em>{lastMessage.raised}</em>
              {audienceTitle}
              <span>{lastMessage.message}</span>
            </div>
            
            <div className="controls">
              <IconButton onClick={e => call(e, lastMessage.conversationId)} className="icnBtn"><Call /></IconButton>
              <IconButton onClick={e => videoCall(e, lastMessage.conversationId)} className="icnBtn"><VideoCall /></IconButton>
              <Badge onClick={e => toggleConversations(e, audience.key)} badgeContent={lastMessages.length - 1} color="primary"><PrevMessages /></Badge>
            </div>
          </div>            
          
          <ul style={{ display : visibleAudiences[audience.key] ? 'block' : 'none' }} className="prevConversations">
            {(lastMessages || []).map((lm, i) => i ? <li key={lm.conversationId}>
              <ListItem button component={RenderLink(lm.conversationId)} className="audienceListItem">
                  <span>{lm.message}</span>
                  <em>{lm.raised}</em>
              </ListItem>
              </li> : null)}
          </ul>
        </ListItem>    
      </li>
    }
    
    return <div className="conversationsscreen clearfix">
        <ul className="conversationOverview">
          {(overview || []).map((audience) => renderCenversation(self, audience))}
        </ul>
    </div>
}    