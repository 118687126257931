
import { DeckTypes } from './deckTypes';
import { Spreads } from './spreads';

export const DeckSpreads = {
    [DeckTypes.TAROT] : Object.keys(Spreads).slice(0, 22),
    [DeckTypes.TAROT_MAYOR_ARCANA] : Object.keys(Spreads).slice(0, 22),
    [DeckTypes.VOYAGER] : Object.keys(Spreads).slice(0, 22).concat(["relations2", "goals"]),
    [DeckTypes.LENORMAND] : Object.keys(Spreads).slice(24, 24 + 5),
    [DeckTypes.EXTENDED_LENORMAND] : Object.keys(Spreads).slice(24, 24 + 3).concat(["grandtableau1ext", "grandtableau2ext"]),
};