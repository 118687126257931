export const actionTypes = {
    DOMAIN : 'AUTH_ACTIONS',
    SET_REDIRECTLOCATION : 'SET_REDIRECTLOCATION',
    SET_DEVICE_ID : 'SET_DEVICE_ID',
    SET_MOBILE_PHONE : 'SET_MOBILE_PHONE',
    SET_ACTIVATION_CODE : 'SET_ACTIVATION_CODE',
    SET_ACCESSTOKEN : 'SET_ACCESSTOKEN',
    INVALIDATE : 'INVALIDATE',

    SET_OIDC_USER : 'SET_OIDC_USER',
}