import React, { useState, useRef, useEffect } from 'react'
import { REGEXS, KEY_CODES } from '../../../constants/digits';

function* Range(min,ct) {
  for (let x=min;x<min+ct;x++) {
    yield x;
  }
}

export function DigitInputs({ className, onDigitsChange, numberOfDigits, renderInput }) {
  const split = Math.floor(numberOfDigits / 2);
  const digitRef = useRef({});
  
  const [values, setValues] = useState([... Range(0, numberOfDigits)].map(i => ''));
  const [focusedIndex, setFocusedIndex] = useState(null)

  let currentValues = values;

  useEffect(() => {
    const asString = values.join('');
    const asNumber = Number(asString);
    onDigitsChange({
      asNumber,
      asString,
      asArray: values
    });
  }, [values]);

  const handleKeyUpPress = (i, event) => {
    const { BACKSPACE, DELETE, ARROW_LEFT, ARROW_RIGHT } = KEY_CODES

    if ([BACKSPACE, DELETE, ARROW_LEFT].includes(event.keyCode)) {
      if ([BACKSPACE, DELETE].includes(event.keyCode)) {
        currentValues = [...values];
        currentValues[i] = '';
        setValues(currentValues);
      }

      if ([BACKSPACE, ARROW_LEFT].includes(event.keyCode)) {
        if (i > 0) {
          digitRef.current[i - 1].focus();
          setFocusedIndex(i - 1);
        }
      }
    }

    if (event.keyCode === ARROW_RIGHT) {
      var idx = values.findIndex(x => x === '');
      if (i < numberOfDigits - 1 && idx > i) {
        digitRef.current[i + 1].focus();
        setFocusedIndex(i + 1);
      }
    }

    if (!REGEXS.DIGITS.test(event.key)) {
      event.preventDefault()
    } else {
      currentValues = [...values];
      currentValues[i] = event.key;
      setValues(currentValues);
      
      if (i < numberOfDigits - 1) {
        digitRef.current[i + 1].focus();
        setFocusedIndex(i + 1);
      }
    }
  }

  const handleFocus = (i, event) => {
    event.target.setSelectionRange(0, 1);
    setFocusedIndex(i);

    //use own variable, because the state might not be done yet
    var idx = currentValues.findIndex(x => x === '');
    

    if (i > idx && idx > -1) {
      setFocusedIndex(idx);
      digitRef.current[idx].focus();      
    }
  }

  return <div className={className}>
    
    {[... Range(0, split)].map(i => renderInput({
            key : `inp${i}`,
            focused : focusedIndex === i,
            value: values[i],
            
            inputProps : {
              pattern:'[0-9]*',
              inputMode:'tel',
              maxLength:1,      
              onKeyUp: (event) => handleKeyUpPress(i, event),
              ref : (ref) => digitRef.current[i] = ref,              
              onFocus: (event) => handleFocus(i, event)
            }
          }))}

    <span>—</span>

    {[... Range(split, numberOfDigits - split)].map(i => renderInput({
            key : `inp${i}`,
            focused : focusedIndex === i,
            value: values[i],
            
            inputProps : {
              pattern:'[0-9]*',
              inputMode:'tel',
              maxLength:1,
              
              onKeyUp: (event) => handleKeyUpPress(i, event),
              ref : (ref) => digitRef.current[i] = ref,              
              onFocus: (event) => handleFocus(i, event)
            }
          }))}
  </div>
}