import { useEffect } from "react";
import { useSelector } from "react-redux";


const mapState = state => {
    return {
    isAuthenticated:    state.oidcUser || (
                            state.auth &&
                            state.auth.activationCode &&
                            state.auth.mobilePhone
                        )
}};

const useAuth = () => {
    const { isAuthenticated } = useSelector(mapState);

    //only need to redraw when authenticated changes
    //might want to sync the profile with the server
    useEffect(
        () => { },
        [isAuthenticated]
    );

    return isAuthenticated;
};

const WhenAuth = ({ children }) => {    
    return useAuth() ? children[0] : children[1];    
};

export default WhenAuth;