//chatsreducer is a reducer manager, creating multiple reducers; 1 for each conversation
import { ActionTypes, historicEventsReceived } from '../actions/chatActions';
import update from 'immutability-helper';
import chatReducer from './chatReducer';

function getConversationsOverview(self, allConversations) {
    //make it so there is an ordered list based on participants
    let keys = Object.keys(allConversations);
    keys.sort((a, b) => {
        if (allConversations[a].lastMessage && allConversations[b].lastMessage) {
            return allConversations[a].lastMessage.raised < allConversations[b].lastMessage.raised ? 1 : -1;
        }
        if (allConversations[a].lastMessage) return -1;
        return 1;
    });

    let result = [];
    let consultantLink = {};

    keys.forEach(key => {
        let c = allConversations[key];
        let audienceKeys = Object.keys(c.audience);
        let participants = audienceKeys.filter(x => x !== self);
        participants.sort();

        let linkKey = participants.join('-');
        if (consultantLink[linkKey]) {
            consultantLink[linkKey].push({...c.lastMessage, conversationId : key});
        } else {
            let overviewelement = {
                audience : c.audience,
                key : linkKey,
                lastMessages : [ {...c.lastMessage, conversationId : key} ]
            };
            consultantLink[linkKey] = overviewelement.lastMessages;
            result.push(overviewelement);
        }
    });

    return result;
}

function processHistory(state, action) {
    let conversations = state.conversations || {};

    let self = action.self;
    let keys = Object.keys(action.history);
        
    let changes = false;

    let saveObject = {  }
    for (let conversationId of keys) {
        let conversationCommands = action.history[conversationId];

        let newState = chatReducer(conversations[conversationId], historicEventsReceived(self, conversationCommands));
        //let newState = chatReducer(undefined, historicEventsReceived(self, conversationCommands));
    
        if (newState !== conversations[ conversationId ]) {
            saveObject[ conversationId ] = { $set : newState };
            changes = true;
        }
    }

    if (changes || !state.overview) {
        //Fix the Conversations View
        let newConversations = update( conversations, saveObject );

        //also build a view based on the updated conversations
        let overview = getConversationsOverview(action.self, newConversations);

        let result = {
            ...state,
            self: action.self,
            overview : overview,
            conversations : newConversations
        };
    
        return result;
    }
    return state;
}

function processMessage(state, action) {
    let conversations = state.conversations || {};
    let conversationId = action.chatEvent.aggregateId;

    let newState = chatReducer(conversations[conversationId], action);
    if (newState !== conversations[ conversationId ]) {
        let newConversations = update( conversations, { [ conversationId ] : { $set : newState } } );
        let overview = getConversationsOverview(action.self, newConversations);

        let result = {
            ...state,
            self: action.self,
            overview : overview,
            conversations : newConversations
        };

        return result;
    }    
    
    return state;
}

export default function chatsReducer(state = { conversations : {} }, action) {
    if (action.domain !== ActionTypes.DOMAIN)
    return state;

    switch (action.type) {
        case ActionTypes.HISTORY_RECEIVED:
            return processHistory(state, action);

        case ActionTypes.MESSAGE_RECEIVED:
            return processMessage(state, action);

        default : return state;
    }
}