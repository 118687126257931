import './spreadselection.scss';
import { useParams } from "react-router-dom";
import { DeckSpreads } from '../../../constants/deckSpreads';
import { Spreads } from '../../../constants/spreads';
import { Tabs, Tab, Box, ListItem, Typography } from '@material-ui/core';

import { Link } from 'react-router-dom';

import { useHistory } from "react-router-dom";
import React from 'react';


function SpreadLink(idx, spread, decktype) {
  let to = `/spread/${spread.id}/${decktype}`;
  let key = "/spread";
  
  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={{ pathname: to, key: key }} ref={ref} {...itemProps} />),
    [to],
  );

  let images = [];      
  spread.positions.forEach((p, j) => {
    images.push(<image
      key={`cardpos-${idx}-${j}`}
      style={{ opacity : 0.9, pointerEvents : 'none', transformOrigin: `${p.centerX}px ${p.centerY}px`, transform: `rotate(${p.rotation}rad)` }}
      preserveAspectRatio="xMidYMid meet"
      xlinkHref="./images/back.jpg"
      x={p.centerX - spread.cardWidth / 2}
      y={p.centerY - spread.cardHeight / 2}
      width={spread.cardWidth}
      height={spread.cardHeight} />);
    });

  let spreadLocale = spread.text.nl;


  return (
    (<li key={`spread-${spread.id}`} >
      <ListItem button component={renderLink} className="listItem">
        <Typography component="div">
          <h2>{spreadLocale.title}</h2>
          <figure>
            <svg viewBox={`0 0 ${spread.width}  ${spread.height}`}>
              {images}
            </svg>
          </figure>
          <p dangerouslySetInnerHTML={{__html: spreadLocale.description}}></p>        
        </Typography>
      </ListItem>
    </li>));
}

function RenderSpreadList( deckSpread ) {
    let spreads = DeckSpreads[deckSpread];

    //make hashmap containing all
    var spreadsInDeck = spreads.reduce(function(map, obj) {
        map[obj] = true;
        return map;
    }, {});    

    let allSpreads = Object.keys(Spreads).map(key => Spreads[key]);
    allSpreads.sort((a, b) => a.positions.length - b.positions.length);

    return <ul>
     {allSpreads.map((spread, i) => {
       if (!spreadsInDeck[spread.id]) return null

      
        return SpreadLink(i, spread, deckSpread);
    })}
    </ul>;
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    if (value !== index) return null;

    return (
      <div {...other}>
        <Box>
          {children}
        </Box>
      </div>);
  }
  

export const SpreadSelectionScreen = () => {
    const { decktype } = useParams();
    const deckTypes = Object.keys(DeckSpreads);
    const history = useHistory();

    let selectedDeckType = deckTypes.filter(x => x === decktype)[0] || deckTypes[0];

    const handleChange = (event, newValue) => {
        history.push(`/spreads/${newValue}`)
    };

    return (
        <div className="spreadSelection">
            <p>EXPLANATION-SPREADSLECTION-BY-DECKTYPE</p>
            <Tabs value={selectedDeckType} onChange={handleChange}>
                {deckTypes.map(key => <Tab key={key} value={key} label={key} />)}
            </Tabs>

            {deckTypes.map(key => <TabPanel key={key} value={selectedDeckType} index={key} className="spreadSelection">{RenderSpreadList(key)}</TabPanel>)}            
        </div>
    )
}