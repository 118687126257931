import './reader.scss';
import React from 'react';
import { useParams } from "react-router-dom";

import { getConsultants } from "../../../services/consultants";

import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar
  } from '@material-ui/core';
  
  import {
    PermPhoneMsgTwoTone as Call,
    VideocamTwoTone as VideoCall,
    ChatTwoTone as Messages
  } from '@material-ui/icons';
  
  import { Link, useHistory } from 'react-router-dom';

import { v5 as uuidv5 } from 'uuid';
import { useSelector, useDispatch } from "react-redux";

import { sendInvite } from '../../../actions/chatActions';
import { startCall } from '../../../actions/rtcActions';

import { ReaderLarge } from '../../parts/reader/large';

function ListItemLink(props) {
    const { icon, primary, to } = props;
  
    const renderLink = React.useMemo(
      () => React.forwardRef((itemProps, ref) => <Link to={{ pathname: to, key: to }} ref={ref} {...itemProps} />),
      [to],
    );
  
    return (
      <ListItem button component={renderLink} className="listItem">
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>      
    );
  }

  function callConsultant(dispatch, history, userId, displayName, consultantId, consultantDisplayName, useVideo) {
    //if the userId is not known: he/she needs to login  
    if (userId) {
      let conversationId = uuidv5(userId, consultantId);
  
      //dispatch the invite
      dispatch(sendInvite(conversationId, userId, displayName, consultantId, consultantDisplayName));
      
      //then dispatch starting the call
      dispatch(startCall(conversationId, useVideo));
    } else {
      //redirect to signup
      history.push("/signup");
    }
  }

  function CallDirectLink(props) {
    const { icon, primary, userId, displayName, consultantId, consultantDisplayName, useVideo } = props;
    const dispatch = useDispatch();
    const history = useHistory();
  
    //const renderLink = ({itemProps}) => <a onClick={() => callConsultant(dispatch, userId, consultantId, useVideo)} {...itemProps} />;
    const renderLink = React.useMemo(
      () => React.forwardRef((itemProps, ref) => <a ref={ref} {...itemProps} />),
      [],
    );
  
    return (
      <ListItem button component={renderLink} className="listItem" onClick={() => callConsultant(dispatch, history, userId, displayName, consultantId, consultantDisplayName, useVideo)}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />      
      </ListItem>      
    );
  }

export const ReaderScreen = () => {
    const { consultantId } = useParams();
    const [consultant, setConsultant] = React.useState({});
    const {userId, displayName} = useSelector(state => ({ userId : state.auth.userId, displayName : state.profile.displayName }));

    React.useEffect(() => {
        getConsultants().then(x => {
            setConsultant(x.data.filter(x => x.userId === consultantId)[0] || {});
        });
    }, []);

    return <div className="readerscreen clearfix">
        <ReaderLarge {...consultant}>
            <div className="contactreader">                
                <Toolbar />
                <div className="buttons">
                    <strong>Direct contact</strong>
                    <strong className="price">&euro; 1,50 /m</strong>

                    <CallDirectLink useVideo={false} userId={userId} consultantId={consultant.userId} displayName={displayName} consultantDisplayName={consultant.displayName} primary="Call Now" icon={<Call />} />
                    <CallDirectLink useVideo={true} userId={userId}  consultantId={consultant.userId} displayName={displayName} consultantDisplayName={consultant.displayName} primary="Video Call" icon={<VideoCall />} />
                                            
                    {/* <ListItemLink to="/spreads" primary="Chat nu" icon={<Messages />} /> */}
                </div>            
            </div>
        </ReaderLarge>

        <div className="content">
            <div className="properties">
                <dl>
                    <dt>Expertises</dt>
                    <dd>
                        <ul>
                            {consultant.expertise?.map(l => <li key={l}>{l}</li>  )}
                        </ul>
                    </dd>
                </dl>   
                <dl>
                    <dt>I Speak</dt>
                    <dd>
                        <ul>
                            {consultant.languages?.map(l => <li key={l}>{l}</li>  )}
                        </ul>
                    </dd>
                </dl>
            </div>

            <p>{consultant.aboutShort}</p>

            <div className="reviews">
                <h4>Client reviews</h4>
                <ul>
                    <li>
                        <span className="rating">4 sterren</span>
                        <h4>B. Bob</h4>
                        <p>Naomi helped me a lot regarding the filling of biographies. Very Good!</p>
                        <span className="date">30-11-2020</span>
                    </li>
                    <li>
                        <span className="rating">4 sterren</span>
                        <h4>B. Bob</h4>
                        <p>Naomi helped me a lot regarding the filling of biographies. Very Good!</p>
                        <span className="date">30-11-2020</span>
                    </li>
                    <li>
                        <span className="rating">4 sterren</span>
                        <h4>B. Bob</h4>
                        <p>Naomi helped me a lot regarding the filling of biographies. Very Good!</p>
                        <span className="date">30-11-2020</span>
                    </li>
                    <li>
                        <span className="rating">4 sterren</span>
                        <h4>B. Bob</h4>
                        <p>Naomi helped me a lot regarding the filling of biographies. Very Good!</p>
                        <span className="date">30-11-2020</span>
                    </li>
                    <li>
                        <span className="rating">4 sterren</span>
                        <h4>B. Bob</h4>
                        <p>Naomi helped me a lot regarding the filling of biographies. Very Good!</p>
                        <span className="date">30-11-2020</span>
                    </li>
                    <li>
                        <span className="rating">4 sterren</span>
                        <h4>B. Bob</h4>
                        <p>Naomi helped me a lot regarding the filling of biographies. Very Good!</p>
                        <span className="date">30-11-2020</span>
                    </li>
                    <li>
                        <span className="rating">4 sterren</span>
                        <h4>B. Bob</h4>
                        <p>Naomi helped me a lot regarding the filling of biographies. Very Good!</p>
                        <span className="date">30-11-2020</span>
                    </li>
                    <li>
                        <span className="rating">4 sterren</span>
                        <h4>B. Bob</h4>
                        <p>Naomi helped me a lot regarding the filling of biographies. Very Good!</p>
                        <span className="date">30-11-2020</span>
                    </li>
                </ul>
            </div>

            
            <div className="moreInfo">
                <p>{consultant.aboutText}</p>
            </div>             
        </div>      
    </div>
}    